import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import { io, Socket } from "socket.io-client";

// Inline type-definitie
declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $socket: Socket;
  }
}

const app = createApp(App);

app.use(store).use(router).use(VueAxios, axios);

const socket: Socket = io("https://ai.alialsof.nl", {
  path: "/api",
});

// We voegen de socket toe aan globalProperties
app.config.globalProperties.$socket = socket;

app.mount("#app");
