import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "brainstorm" }
const _hoisted_2 = { class: "content-wrapper" }
const _hoisted_3 = { id: "brainstorm-area" }
const _hoisted_4 = { class: "cloud-content" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "author" }
const _hoisted_7 = { class: "interaction-area" }
const _hoisted_8 = { class: "qr-section" }
const _hoisted_9 = { ref: "qrContainer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "thought-bubble" }, [
        _createElementVNode("div", { class: "bubble-text" }, "Wat merkte je in je lijf?")
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.responses, (response, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "cloud",
            style: _normalizeStyle(_ctx.getBubbleStyle())
          }, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("q", {
                innerHTML: _ctx.getResponseText(response)
              }, null, 8, _hoisted_5),
              _createElementVNode("span", _hoisted_6, "- " + _toDisplayString(_ctx.getResponseAuthor(response)), 1)
            ])
          ], 4))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, null, 512),
          _cache[1] || (_cache[1] = _createElementVNode("p", null, "Scan om deel te nemen", -1))
        ]),
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToCategories && _ctx.goToCategories(...args))),
          class: "next-button"
        }, _cache[2] || (_cache[2] = [
          _createElementVNode("span", null, "Volgende", -1),
          _createElementVNode("svg", {
            class: "arrow",
            viewBox: "0 0 24 24"
          }, [
            _createTextVNode(" // eslint-disable-next-line "),
            _createElementVNode("path", { d: "M12 5l7 7-7 7M5 12h14" })
          ], -1)
        ]))
      ])
    ])
  ]))
}