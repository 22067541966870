import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate"; // Import de plugin

export class Participant {
  name: string;
  qrCode: string;
  qrCodeUrl: string; // Toegevoegd
  scanned = false;
  id: string;

  constructor(name: string, qrCode: string, id: string, qrCodeUrl = "") {
    this.name = name;
    this.qrCode = qrCode;
    this.qrCodeUrl = qrCodeUrl;
    this.id = id;
  }
}

export default createStore({
  state: {
    participantCount: 0,
    selectedTraining: "",
    sessionStarted: false,
    trainerId: "",
    participants: [] as Participant[], // Lijst van deelnemers
  },
  mutations: {
    SET_PARTICIPANT_COUNT(state, count) {
      console.log("SET_PARTICIPANT_COUNT called with count:", count);
      state.participantCount = count;
    },
    SET_SELECTED_TRAINING(state, training) {
      console.log("SET_SELECTED_TRAINING called with training:", training);
      state.selectedTraining = training;
    },
    SET_SESSION_STARTED(state, started) {
      console.log("SET_SESSION_STARTED called with started:", started);
      state.sessionStarted = started;
    },
    SET_TRAINER_ID(state, trainerId) {
      console.log("SET_TRAINER_ID called with trainerId:", trainerId);
      state.trainerId = trainerId;
    },
    SET_PARTICIPANTS(state, participants: Participant[] = []) {
      console.log("SET_PARTICIPANTS called with participants:", participants);
      state.participants = participants || []; // Zorg ervoor dat participants altijd een array is
    },
    ADD_PARTICIPANT(
      state,
      participantData: { name: string; qrCode: string; id: string }
    ) {
      console.log(
        "ADD_PARTICIPANT called with participantData:",
        participantData
      );
      const newParticipant = new Participant(
        participantData.name,
        participantData.qrCode,
        participantData.id
      );
      state.participants.push(newParticipant);
      console.log("Participants after adding:", state.participants);
    },
    UPDATE_PARTICIPANT(state, updatedParticipant: Participant) {
      console.log("UPDATE_PARTICIPANT called with:", updatedParticipant);
      const index = state.participants.findIndex(
        (p) => p.id === updatedParticipant.id
      );
      if (index !== -1) {
        // Zorg ervoor dat je een nieuwe `Participant` instantie maakt voor de update
        state.participants.splice(
          index,
          1,
          new Participant(
            updatedParticipant.name,
            updatedParticipant.qrCode,
            updatedParticipant.id,
            updatedParticipant.qrCodeUrl
          )
        );
        console.log("Updated participant in store:", state.participants[index]);
      } else {
        console.error(
          "Deelnemer niet gevonden voor update:",
          updatedParticipant
        );
      }
    },
    REMOVE_QR_CODE(state, index: number) {
      console.log(`REMOVE_QR_CODE called for index ${index}`);
      if (state.participants && state.participants.length > index) {
        const updatedParticipant = {
          ...state.participants[index],
          qrCode: "",
          scanned: false,
        };
        state.participants.splice(index, 1, updatedParticipant);
      } else {
        console.error("Invalid participant index:", index);
      }
    },
    MARK_AS_SCANNED(state, index: number) {
      console.log(`MARK_AS_SCANNED called for index ${index}`);
      if (state.participants && state.participants.length > index) {
        const updatedParticipant = {
          ...state.participants[index],
          scanned: true,
        };
        state.participants.splice(index, 1, updatedParticipant);
      } else {
        console.error("Invalid participant index:", index);
      }
    },
    REGENERATE_QR_CODE(state, index: number) {
      console.log(`REGENERATE_QR_CODE called for index ${index}`);
      if (state.participants && state.participants.length > index) {
        const updatedParticipant = {
          ...state.participants[index],
          scanned: false,
        };
        state.participants.splice(index, 1, updatedParticipant); // Zet 'scanned' terug naar false
      } else {
        console.error("Invalid participant index:", index);
      }
    },
  },
  actions: {
    startSession({ commit }, { participants, selectedTraining, trainerId }) {
      console.log("startSession called");
      commit("SET_PARTICIPANTS", participants);
      commit("SET_SELECTED_TRAINING", selectedTraining);
      commit("SET_SESSION_STARTED", true);
      commit("SET_TRAINER_ID", trainerId);
    },
    endSession({ commit }) {
      console.log("endSession called");
      commit("SET_PARTICIPANT_COUNT", 0);
      commit("SET_SELECTED_TRAINING", "");
      commit("SET_SESSION_STARTED", false);
      commit("SET_PARTICIPANTS", []);
    },
    removeQRCode({ commit }, index: number) {
      console.log(`removeQRCode called for index ${index}`);
      commit("REMOVE_QR_CODE", index);
    },
    markAsScanned({ commit }, index: number) {
      console.log(`markAsScanned called for index ${index}`);
      commit("MARK_AS_SCANNED", index);
    },
    regenerateQRCode({ commit }, index: number) {
      console.log(`regenerateQRCode called for index ${index}`);
      commit("REGENERATE_QR_CODE", index);
    },
  },
  getters: {
    isSessionActive(state) {
      return state.sessionStarted;
    },
    getParticipantCount(state) {
      return state.participantCount;
    },
    getSelectedTraining(state) {
      return state.selectedTraining;
    },
    getTrainerId(state) {
      return state.trainerId;
    },
    getParticipants(state) {
      return state.participants;
    },
  },
  plugins: [createPersistedState()], // Zorgt voor persistente state
});
