/* stylelint-disable */
<template>
  <div class="brainstorm">
    <div class="content-wrapper">
      <div class="thought-bubble">
        <div class="bubble-text">Wat merkte je in je lijf?</div>
      </div>
      <div id="brainstorm-area">
        <div
          v-for="(response, index) in responses"
          :key="index"
          class="cloud"
          :style="getBubbleStyle()"
        >
          <div class="cloud-content">
            <q v-html="getResponseText(response)"></q>
            <span class="author">- {{ getResponseAuthor(response) }}</span>
          </div>
        </div>
      </div>
      <div class="interaction-area">
        <div class="qr-section">
          <div ref="qrContainer"></div>
          <p>Scan om deel te nemen</p>
        </div>
        <button @click="goToCategories" class="next-button">
          <span>Volgende</span>
          <svg class="arrow" viewBox="0 0 24 24">
            // eslint-disable-next-line
            <path d="M12 5l7 7-7 7M5 12h14" />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import QRCode from "qrcode";
import { io, Socket } from "socket.io-client";

export default defineComponent({
  name: "BrainstormView",
  setup: function () {
    const responses = ref<string[]>([]);
    const router = useRouter();
    const qrContainer = ref<HTMLDivElement | null>(null);
    let socket: Socket | undefined;

    const generateQRCode = () => {
      console.log("BrainstormView: QR-code wordt gegenereerd...");
      if (qrContainer.value) {
        const canvas = document.createElement("canvas");
        canvas.width = 128;
        canvas.height = 128;
        qrContainer.value.innerHTML = "";
        qrContainer.value.appendChild(canvas);

        const url = new URL("/mobile", window.location.origin).toString();
        QRCode.toCanvas(canvas, url, { width: 128 }, (error) => {
          if (error) {
            console.error(
              "BrainstormView: Fout bij genereren van QR-code",
              error
            );
          } else {
            console.log("BrainstormView: QR-code succesvol gegenereerd");
          }
        });
      }
    };

    const getBubbleStyle = () => {
      console.log("BrainstormView: Stijl voor bubbles wordt gegenereerd...");
      const colors = ["#FFD700", "#FF6B6B", "#4ECDC4", "#45B7D1"];
      const style = {
        backgroundColor: colors[Math.floor(Math.random() * colors.length)],
        left: `${Math.random() * 80}%`,
        top: `${Math.random() * 80}%`,
        transform: `scale(${0.8 + Math.random() * 0.4})`,
        animationDelay: `${Math.random() * 2}s`,
      };
      console.log("BrainstormView: Bubbles stijl gegenereerd:", style);
      return style;
    };
    const wrapTextCharacters = (text: string): string => {
      return text
        .split("")
        .map(
          (char, index) =>
            `<span style="animation-delay: ${index * 0.1}s">${char}</span>`
        )
        .join("");
    };

    const getResponseText = (response: string): string => {
      console.log(
        "BrainstormView: Tekst uit response wordt gehaald:",
        response
      );
      const text = response.split(" - ")[0].replace(/[()]/g, "").trim();
      console.log("BrainstormView: Geëxtraheerde tekst:", text);
      return wrapTextCharacters(text); // Hier passen we de wrapTextCharacters toe
    };

    const getResponseAuthor = (response: string): string => {
      console.log(
        "BrainstormView: Auteur uit response wordt gehaald:",
        response
      );
      const author = response.split(" - ")[1] || "";
      console.log("BrainstormView: Geëxtraheerde auteur:", author);
      return author;
    };

    const goToCategories = () => {
      console.log("BrainstormView: Navigeren naar categorieën...");
      router.push("/categories");
    };

    onMounted(() => {
      console.log(
        "BrainstormView: Component is gemount. QR-code wordt geïnitialiseerd..."
      );
      generateQRCode();

      console.log("BrainstormView: Socket.IO verbinding wordt opgezet...");
      socket = io("https://ai.alialsof.nl", {
        path: "/api",
        transports: ["websocket"],
        upgrade: false,
      });

      if (socket) {
        console.log("BrainstormView: Socket.IO succesvol verbonden:", socket);

        socket.on("connect", () => {
          console.log("BrainstormView: Verbonden met de server via WebSocket");

          if (
            socket &&
            socket.io &&
            socket.io.engine &&
            socket.io.engine.transport
          ) {
            const transportType = socket.io.engine.transport.name;
            console.log(
              `BrainstormView: Actief transportmiddel: ${transportType}`
            );
          } else {
            console.warn(
              "BrainstormView: Transportnaam kon niet worden opgehaald."
            );
          }

          socket?.on("update_responses", (data: { responses: string[] }) => {
            console.log(
              "BrainstormView: Received 'update_responses' event from server",
              data
            );
            if (data.responses && data.responses.length > 0) {
              responses.value.push(...data.responses);
              console.log(
                "BrainstormView: Updated responses:",
                responses.value
              );
            } else {
              console.log(
                "BrainstormView: Geen responses ontvangen in 'update_responses' event"
              );
            }
          });
        });

        socket.on("disconnect", (reason) => {
          console.log(`BrainstormView: Verbinding verbroken. Reden: ${reason}`);
        });

        socket.on("connect_error", (error) => {
          console.error(`BrainstormView: Fout bij verbinden: ${error.message}`);
        });
      } else {
        console.error("BrainstormView: Socket.IO is niet beschikbaar");
      }
    });

    return {
      responses,
      getBubbleStyle,
      goToCategories,
      qrContainer,
      getResponseText,
      getResponseAuthor,
    };
  },
});
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Bangers&family=Quicksand:wght@300;400;500;600;700&display=swap");

.brainstorm {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #74ebd5, #acb6e5);
  padding: 2rem;
  font-family: "Quicksand", sans-serif;
  color: #333;
}

.content-wrapper {
  width: 90%;
  max-width: 1200px;
  aspect-ratio: 16 / 9;
  position: relative;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 30px;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  overflow: hidden;
  padding: 2rem;
}

.thought-bubble {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  aspect-ratio: 1 / 1;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  animation: pulsate 4s ease-in-out infinite;
  z-index: 1;
}

.thought-bubble::before,
.thought-bubble::after {
  content: "";
  position: absolute;
  background-color: white;
  border-radius: 50%;
}

.thought-bubble::before {
  width: 50px;
  height: 50px;
  bottom: -25px;
  left: 40px;
}

.thought-bubble::after {
  width: 30px;
  height: 30px;
  bottom: -50px;
  left: 20px;
}

.bubble-text {
  font-family: "Bangers", cursive;
  font-size: 2.5vw;
  color: #333;
  text-align: center;
  padding: 1rem;
  line-height: 1.2;
  transform: rotate(-5deg);
  text-shadow: 2px 2px 0 #fff, 4px 4px 0 rgba(0, 0, 0, 0.15);
}

@keyframes pulsate {
  0%,
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    transform: translate(-50%, -50%) scale(1.05);
  }
}

#brainstorm-area {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  pointer-events: none;
}

.row-content {
  position: relative;
  min-height: 500px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
@import url("https://fonts.googleapis.com/css2?family=Balsamiq+Sans&display=swap");

.row-content {
  position: relative;
  min-height: 500px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.cloud {
  position: absolute;
  width: 220px;
  height: 440px;
  max-width: 90%;
  background: white;
  border-radius: 25px;
  padding: 1.2rem;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease;
  border: 3px solid #333;
  font-family: "Balsamiq Sans", cursive;
  transform-origin: center center;
}

.cloud::before {
  content: "";
  position: absolute;
  bottom: -15px;
  left: 20px;
  width: 30px;
  height: 15px;
  background: white;
  border-right: 3px solid #333;
  border-bottom: 3px solid #333;
  transform: skew(0deg, -20deg);
}

.cloud:hover {
  transform: scale(1.05) rotate(-2deg);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.cloud-content {
  position: relative;
  z-index: 1;
}

.cloud-content q {
  font-style: italic;
  color: #333;
  font-size: 1.1rem;
  line-height: 1.4;
  display: block;
  margin-bottom: 0.8rem;
}

.cloud-content .author {
  display: block;
  text-align: right;
  font-size: 1rem;
  color: #666;
  font-weight: bold;
}

.cloud-content .author::before {
  content: "- ";
  color: #999;
}

/* Onafhankelijke animaties voor elke cloud */
.cloud:nth-child(3n) {
  animation: float1 7s ease-in-out infinite;
}

.cloud:nth-child(3n + 1) {
  animation: float2 8s ease-in-out infinite;
}

.cloud:nth-child(3n + 2) {
  animation: float3 9s ease-in-out infinite;
}

@keyframes float1 {
  0%,
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
  25% {
    transform: translate(5px, -10px) rotate(2deg);
  }
  75% {
    transform: translate(-5px, 10px) rotate(-2deg);
  }
}

@keyframes float2 {
  0%,
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
  33% {
    transform: translate(-7px, -7px) rotate(-1deg);
  }
  66% {
    transform: translate(7px, 7px) rotate(1deg);
  }
}

@keyframes float3 {
  0%,
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
  50% {
    transform: translate(10px, 5px) rotate(3deg);
  }
}

/* Tekstanimatie binnen de clouds */
@keyframes wavyText {
  0%,
  100% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-2px);
  }
  75% {
    transform: translateY(2px);
  }
}

.cloud-content q span {
  display: inline-block;
  animation: wavyText 2s ease-in-out infinite;
}

/* Positie variaties voor dynamische layout */
.cloud:nth-child(3n) {
  top: 10%;
  left: 5%;
}

.cloud:nth-child(3n + 1) {
  top: 40%;
  right: 5%;
}

.cloud:nth-child(3n + 2) {
  bottom: 15%;
  left: 50%;
  transform: translateX(-50%);
}

/* Kleurvariaties voor verschillende categorieën */
.gedachtesturing .cloud {
  background-color: #e8f5e9;
  border-color: #4caf50;
}
.ademhaling .cloud {
  background-color: #e3f2fd;
  border-color: #2196f3;
}
.spierspanning .cloud {
  background-color: #fff3e0;
  border-color: #ff9800;
}
.fysiologischereactie .cloud {
  background-color: #fce4ec;
  border-color: #e91e63;
}

/* Responsieve aanpassingen */
@media (max-width: 768px) {
  .cloud {
    position: relative;
    width: 90%;
    margin-bottom: 2rem;
  }

  .cloud:nth-child(n) {
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    transform: none;
  }
}

/* Verbindingslijnen voor brainmap effect */
.row-content::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  height: 80%;
  background-image: radial-gradient(circle, #333 1px, transparent 1px),
    radial-gradient(circle, #333 1px, transparent 1px);
  background-size: 20px 20px;
  background-position: 0 0, 10px 10px;
  transform: translate(-50%, -50%);
  opacity: 0.2;
  pointer-events: none;
}
@keyframes float {
  0%,
  100% {
    transform: translateY(0) rotate(0deg);
  }
  50% {
    transform: translateY(-10px) rotate(2deg);
  }
}

/* Positie variaties voor dynamische layout */
.cloud:nth-child(3n) {
  top: 10%;
  left: 5%;
}

.cloud:nth-child(3n + 1) {
  top: 40%;
  right: 5%;
}

.cloud:nth-child(3n + 2) {
  bottom: 15%;
  left: 50%;
  transform: translateX(-50%);
}

/* Kleurvariaties voor verschillende categorieën */
.gedachtesturing .cloud {
  background-color: #e8f5e9;
  border-color: #4caf50;
}
.ademhaling .cloud {
  background-color: #e3f2fd;
  border-color: #2196f3;
}
.spierspanning .cloud {
  background-color: #fff3e0;
  border-color: #ff9800;
}
.fysiologischereactie .cloud {
  background-color: #fce4ec;
  border-color: #e91e63;
}

.interaction-area {
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  right: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 3;
}

.qr-section {
  background: rgba(255, 255, 255, 0.9);
  padding: 1rem;
  border-radius: 15px;
  text-align: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.qr-section p {
  margin-top: 0.5rem;
  font-size: 0.9rem;
  color: #333;
}

.next-button {
  font-family: "Bangers", cursive;
  font-size: 1.2rem;
  color: #fff;
  background-color: #ff6b6b;
  border: none;
  border-radius: 15px;
  width: 120px;
  height: 120px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 6px 0 #c44d58;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  overflow: hidden;
}

.next-button span {
  margin-bottom: 10px;
}

.next-button .arrow {
  width: 30px;
  height: 30px;
  fill: none;
  stroke: currentColor;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  transition: transform 0.3s ease;
}

.next-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 9px 0 #c44d58;
}

.next-button:active {
  transform: translateY(3px);
  box-shadow: 0 3px 0 #c44d58;
}

.next-button::after {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: rgba(255, 255, 255, 0.1);
  transform: rotate(45deg);
  transition: all 0.3s ease;
}

.next-button:hover::after {
  top: -100%;
  left: -100%;
}

.next-button:hover .arrow {
  transform: translateY(3px);
}

@media (max-width: 768px) {
  .next-button {
    width: 100px;
    height: 100px;
    font-size: 1rem;
  }

  .next-button .arrow {
    width: 24px;
    height: 24px;
  }
}
@media (max-width: 768px) {
  .content-wrapper {
    aspect-ratio: auto;
    height: 80vh;
  }

  .bubble-text {
    font-size: 5vw;
  }

  .interaction-area {
    flex-direction: column;
    gap: 1rem;
  }

  .next-button {
    width: 100%;
  }
}
</style>
